#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .title {
  color: black;
  text-align: center;
  text-transform: uppercase;

}

#about .about1 .container .subtitle {
  color: #333333;
}

#about .about1 .about-bg {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
}

#about .about1 .about-bg .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

#about .about1 .about-bg .container .title {
  color: white;
  text-transform: initial;
}

#about .about1 .about-bg .container .text,
#about .about1 .about-bg .container .text2 {
  font-size: 1.2rem;
  text-align: center;
  width: 90%;
}

#about .about1 .about-bg .container .text2 {
  font-weight: 600;
}

#about .about2 {
  background-color: #F6F6F6;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .title {
  color: #1E1E1E;
  text-align: center;
}

#about .about2 .container .subtitle {
  color: #1E1E1E;
}

#about .about2 .container .text {
  color: #1E1E1E;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

#about .about3 .container .gallery {
  display: flex;
  gap: 1rem;
}

#about .about3 .container .bottom {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

#about .about3 .container .bottom #ctaForm,
#about .about3 .container .bottom #ctaCatalogo {
  text-align: center;
}

#about .about3 .container .bottom #ctaForm,
#about .about3 .container .bottom #ctaCatalogo {
  width: 30%;
}

#about .about3 .container .bottom #ctaForm img,
#about .about3 .container .bottom #ctaCatalogo img {
  width: 100%;
}

#about .about4 {
  background: url("../../assets/about4.png") no-repeat center center;
  background-size: cover;
  height: 75vh;
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#about .about4 .container .title {
  color: white;
  text-transform: initial;
}

#about .about4 .container .text,
#about .about4 .container .text2 {
  font-size: 1.2rem;
  text-align: center;
  width: 90%;
}

#about .about4 .container .text2 {
  font-weight: 600;
}

#about .about5 {
  background-color: #F6F6F6;
  display: flex;
  justify-content: space-between;
}

#about .about5 .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  padding: 1rem 2rem;
  width: 50%;
}

#about .about5 .left .bottom {
  display: flex;
}

#about .about5 .left .title {
  font-size: 2.5rem;
  color: black;
}

#about .about5 .left .text {
  color: black;
}

#about .about5 .left .bottom {
  display: flex;
  gap: 2rem;
}

#about .about5 .left .bottom #ctaForm,
#about .about5 .left .bottom #ctaCatalogo {
  text-align: center;
}

#about .about5 .left .bottom #ctaForm,
#about .about5 .left .bottom #ctaCatalogo {
  width: 40%;
}

#about .about5 .left .bottom #ctaForm img,
#about .about5 .left .bottom #ctaCatalogo img {
  width: 100%;
}

#about .about5 .right {
  width: 40%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .about-bg .container {
    height: 50vh;
  }

  #about .about3 .container .gallery {
    flex-direction: column;
  }

  #about .about3 .container .bottom {
    flex-direction: column;
    gap: .5rem;
  }

  #about .about3 .container .bottom #ctaForm,
  #about .about3 .container .bottom #ctaCatalogo {
    width: 100%;
  }

  #about .about3 .container .bottom #ctaForm img,
  #about .about3 .container .bottom #ctaCatalogo img {
    width: 60%;
  }

  #about .about4 {
    height: 50vh;
  }

  #about .about5 {
    flex-direction: column;
  }
  
  #about .about5 .left {
    gap: 2rem;
    align-items: center;
    width: 100%;
  }
  
  #about .about5 .left .bottom {
    display: flex;
  }
  
  #about .about5 .left .title {
    text-align: center;
  }
  
  #about .about5 .left .text {
    text-align: center;
  }
  
  #about .about5 .left .bottom {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  #about .about5 .left .bottom #ctaForm,
  #about .about5 .left .bottom #ctaCatalogo {
    text-align: center;
  }
  
  #about .about5 .left .bottom #ctaForm,
  #about .about5 .left .bottom #ctaCatalogo {
    width: 100%;
  }
  
  #about .about5 .left .bottom #ctaForm img,
  #about .about5 .left .bottom #ctaCatalogo img {
    width: 80%;
  }
  
  #about .about5 .right {
    width: 100%;
    order: -1;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .about-bg .container {
    height: 90vh;
  }

  #about .about3 .container .bottom #ctaForm img,
  #about .about3 .container .bottom #ctaCatalogo img {
    width: 80%;
  }

  #about .about4 {
    height: 90vh;
    padding-top: 2rem;
  }

  #about .about5 .left .title {
    font-size: 1.7rem;
  }

  #about .about5 .right {
    width: 100%;
    order: -1;
  }

  #about .about5 .left .bottom #ctaForm img,
  #about .about5 .left .bottom #ctaCatalogo img {
    width: 90%;
  }
}