#footer .footer1 {
    background-color: #302F2F;
}

#footer .footer1 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#footer .footer1 .container figure img {
    width: 20%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid #272727;
}

#footer .footer2 .container .text, 
#footer .footer2 .container .text2 {
    color: #4A4A4A;
} 

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 30%;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }

    #footer .footer2 .container .text2 {
        border-top: 1px solid #D9D9D9;
        width: 95vw;
        text-align: center;
        padding-top: .5rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container figure img {
        width: 40%;
    }

    #footer .footer2 .container .text { 
        text-align: center;
    }
}