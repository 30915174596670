#success {
    background: url("../../assets/BANNER_TOPO.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: center;
}

#success .container .success-title {
    color: white;
    width: 45%;
    font-size: 2.5rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #success {
        background: url("../../assets/mobile/BANNER_TOPO.png") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }

    #success .container .success-title {
        width: 60%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #success .container {
        align-items: flex-start;
    }
    
    #success .container .success-title {
        width: 90%;
    }
}