#menu .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .left {
    text-align: left;
}

#menu .container .left img {
    width: 30%;
}

#menu .container .right {
    display: flex;
    gap: 1rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        flex-direction: column;
        gap: 1rem;
    }

    #menu .container .left {
        text-align: center;
    }

    #menu .container .left img {
        width: 45%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .left img {
        width: 50%;
    }
}