#form {
    color: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    width: 80%;
    padding-left: 25rem;
}

#form .form-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

#form .form-subtitle {
    font-weight: 400;
}

#form .form-div1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div1 label {
    margin-top: .3rem;
    font-weight: 400;
}

#form .form-div1 span {
    color: red;
}

#form .form-div1 input,
#form .form-div1 select {
    border-radius: 5px;
    padding: .5rem 5px;
    background-color: #F7FAFD;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 80%;
}

#form .form-div1 .row {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div1 .row .top,
#form .form-div1 .row .bottom {
    display: flex;
    justify-content: space-between;
}

#form .form-div1 .row .top label {
    margin-right: 5rem;
}

#form .form-div1 .row .left,
#form .form-div1 .row .right {
    display: flex;
    flex-direction: column;
    gap: 0;
}

#form .form-div1 #servico option {
    text-transform: uppercase;
}

#form .form-div2 .checkbox {
    margin-top: 1rem;
}

#form .form-div2 .checkbox label {
    margin-left: .5rem;
}

#form .form-div2 .checkbox button {
    background-color: transparent;
    cursor: pointer;
    margin-top: .5rem;
    width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #form {
        width: 70%;
        padding-left: 0;
        margin: 0 auto;
    }

    #form .form-div1 input,
    #form .form-div1 select {
        width: 100%;
    }

    #form .form-div2 .checkbox {
        text-align: left;
    }

    #form .form-div2 .checkbox button {
        width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #form {
        width: 100%;
        margin-left: 1rem;
    }
}