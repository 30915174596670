#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content1 .container .title {
  text-transform: uppercase;
  color: black;
  text-align: center;
  font-size: 3rem;
}

#content .content1 .container .gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#content .content1 .container .gallery figure {
  width: 46%;
}

#content .content1 .container #ctaForm {
  text-align: center;
}

#content .content2 {
  background-color: #F6F6F6;
}

#content .content2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content2 .container .title {
  color: #A11F1F;
  text-align: center;
}

#content .content2 .container .subtitle {
  color: black;
  font-weight: 700;
  width: 60%;
  margin: 0 auto;
}

#content .content2 .container .bottom {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

#content .content2 .container .bottom #ctaWpp,
#content .content2 .container .bottom #ctaTel {
  width: 25%;
}

#content .content2 .container .bottom #ctaWpp img,
#content .content2 .container .bottom #ctaTel img {
  width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container .gallery {
    flex-direction: column;
    gap: 2rem;
  }

  #content .content1 .container .gallery figure {
    width: 100%;
  }

  #content .content1 .container #ctaForm img {
    width: 50%;
  }

  #content .content2 .container .subtitle {
    width: 80%;
  }
  
  #content .content2 .container .bottom {
    flex-direction: column;
  }
  
  #content .content2 .container .bottom #ctaWpp,
  #content .content2 .container .bottom #ctaTel {
    width: 100%;
  }
  
  #content .content2 .container .bottom #ctaWpp img,
  #content .content2 .container .bottom #ctaTel img {
    width: 50%;
  }
  
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container #ctaForm img {
    width: 80%;
  }

  #content .content2 .container .bottom {
    gap: .5rem;
  }

  #content .content2 .container .bottom #ctaWpp img,
  #content .content2 .container .bottom #ctaTel img {
    width: 80%;
  }
}