#header .header1 {
    background: url("../../assets/BANNER_TOPO.png") no-repeat center center;
    background-size: cover;
    height: 100%;
}

#header .header1 .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

#header .header1 .container .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    width: 50%;
}

#header .header1 .container .left .text {
    font-size: 1.1rem;
}

#header .header1 .container .left #ctaForm img {
    width: 60%;
}

#header .mobile .container {
    display: none;
}

#header .header2 {
    background-color: #9E1212;
    display: flex;
    justify-content: space-between;
}

#header .header2 .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    padding: 1rem 2rem;
    width: 50%;
}

#header .header2 .left .title {
    font-size: 3rem;
    text-transform: uppercase;
}

#header .header2 .left #ctaForm img {
    width: 50%;
}

#header .header2 .right {
    width: 55%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/BANNER_TOPO.png") no-repeat center center;
        background-size: cover;
        height: 90vh;
    }

    #header .header1 .container .left {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        width: 60%;
    }

    #header .header1 .container .title {
        text-align: left;
    }

    #header .header1 .container .left .text {
        font-size: 1.3rem;
    }

    #header .header1 .container .left #ctaForm img {
        width: 90%;
    }

    #header .header1 .container #form {
        display: none;
    }

    #header .mobile {
        background-color: #761313;
    }

    #header .mobile .container {
        display: block;
    }

    #header .header2 {
        flex-direction: column;
    }

    #header .header2 .left {
        align-items: center;
        gap: 2rem;
        width: 100%;
    }

    #header .header2 .left .title {
        font-size: 2.5rem;
    }

    #header .header2 .left .text {
        text-align: center;
    }

    #header .header2 .left #ctaForm {
        text-align: center;
    }

    #header .header2 .left #ctaForm img {
        width: 80%;
    }

    #header .header2 .right {
        width: 100%;
        order: -1;
    }

    #header .header2 .right img {
        width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .left #ctaForm img {
        width: 100%;
    }
}